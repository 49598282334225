// fonts

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

$main-font: 'Roboto', sans-serif;
$backup-font: 'Nunito Sans', sans-serif;

//colors

$main-orange: #F3B175;
$main-border: #CBCDCF;
$main-green: #9DCC9B;
$main-grey: #A4A4A4;
$main-blue: #0282DA;
$gray: #393939;
$white: #FFFFFF;
$black: #000000;

//metrics

$smallPadding: 5;
$doubleSmallPadding: 10;
$mediumPadding: 15;
$doubleMediumPadding: 30;
$padding20: 20;
$padding45: 45;
$paddingHorizontal: 25;
$paddingVertical: 25;
$smallMargin: 5;
$doubleSmallMargin: 10;
$baseMargin: 15;
$doubleBaseMargin: 30;
$mediumMargin: 20px;
$doubleMediumMargin: 40;
$margin25: 25;

