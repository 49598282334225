@import "../../../../../style/_variables";

#full-calendar {
  font-family: sans-serif;
  text-align: center;
  position: relative;

  --fc-today-bg-color: #fff;
  --fc-non-business-color: rgba(211, 211, 211, 0.2);

  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }

  .fc-v-event {
    border: none;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0;
  }

  .fc .fc-view-harness {
    max-height: calc(100vh - 150px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .fc-license-message {
    display: none;
  }

  .fc-col-header .fc-timegrid-axis {
    border: 0;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 16px 12px !important;
    width: 100%;
  }

  .fc-timegrid-slot-label-frame {
    line-height: 0.75rem;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    padding: 24px;
    margin-bottom: 0;
    border-bottom: 1px solid #E9E9E9;
  }

  .fc-toolbar-chunk {
    display: flex;
    align-items: center;
    gap: 20px;

    // .fc-today-button {
    //   &.fc-button {
    //     &.fc-button-primary {
    //       background-color: unset;
    //       border: none;
    //       color: $main-blue;
    //       text-transform: capitalize;
    //       margin-right: 415px;

    //       &:focus {
    //         box-shadow: none;
    //       }
    //     }
    //   }
    // }
  }

  .fc-toolbar-title {
    display: inline-block !important;
    margin-left: 10px;
    color: $gray;
    font: normal 600 14px/19px Nunito Sans, sans-serif;

    &::after {
      content: "";
      background: url(../../../../../assets/icons/arrow-down.svg) no-repeat bottom;
      width: 24px;
      height: 24px;
      font-weight: normal;
      font-style: normal;
      padding: 2px 13px;
      text-decoration: inherit;
    }
  }

  .fc-prev-button, .fc-next-button {
    &.fc-button {
      &.fc-button-primary {
        background: none;
        border: none;
        width: 37px;
        height: 37px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
          width: 37px;
          height: 37px;
          border-radius: 50%;
        }

        .fc-icon-chevron-left:before {
          color: $gray;
        }

        .fc-icon-chevron-right:before {
          color: $gray;
        }
      }
      .fc-icon {
        font-size: 16px;
      }
    }
  }

  .calendar-header-buttons {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 39px;

    .filterIcon {
      height: 36px;
    }

    .PrivateNotchedOutline-root-1 {
      border: none
    }

    .MuiSelect-icon {
      color: #393939;
    }

    .calendar-mode-dropdown {
      width: 115px;
      height: 36px;
    }

    .calendar-buttons {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .new-appointment-btn {
      height: 36px;
    }
  }

  .calendar-date-picker {
    position: absolute;
    left: 108px;
    top: 34px;

    input {
      font: normal 600 14px/19px Nunito, sans-serif;
      text-transform: capitalize;
      padding: 0;
    }

    .MuiInput-underline:before {
      border: none;
    }

    .MuiSvgIcon-root {
      fill: $main-blue;
      width: 22px;
      height: 22px;
    }

    .MuiInput-underline {
      &::after {
        border: none;
      }
    }

    .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }

  }

  .event-content {
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }

  .event-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 16px;
  }

  .header-text {
    color: #ffffff;
    font: normal 700 12px/16px Nunito Sans, sans-serif;
    text-transform: capitalize;
  }

  .content-container {
    padding: 5px 16px;
    height: calc(100% - 22px);
  }

  .content-text {
    color: #393939;
    font: normal 500 14px/19px Nunito Sans, sans-serif;
    margin-bottom: 0;
    margin-top: 0 ;
  }

  .from-widget-box {
    text-align: right;

    p {
      font: normal 500 10px/15px Nunito Sans, sans-serif;
      color: #323232;
      margin: 0;
    }
  }

  .resources-content {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .staff-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    background: #F2F9FD;
    border: 0.5px solid #A2D1F2;
    color: #A2D1F2;
  }

  .staff-detail {
    text-align: left;
  }

  .staff-name {
    color: #393939;
    font: normal 500 16px/22px Nunito Sans, sans-serif;
    margin: 0 auto;
  }

  .staff-profession {
    color: #393939;
    font: normal 400 14px/19px Nunito Sans, sans-serif;
    margin: 0 auto;
  }

  .loading {
    position: absolute;
    left: 45%;
    top: 45%;
    z-index: 9999;
  }
}

.tooltip-content {
  .tooltip-header {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;

    .header-icon {
      fill: $white;
      font-size: 16px;
    }

    .header-text {
      span {
        color: $white;
        font: normal 700 14px/21px Nunito Sans, sans-serif;
      }
    }
  }

  .tooltip-customer-box {
    padding: 10px 12px;

    .customer-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3px;

      .header-title {
        .title {
          color: #A4A4A4;
          font: normal 600 10px/15px Nunito Sans, sans-serif;
        }
      }

      .header-status {
        span {
          font: normal 500 14px/21px Nunito Sans, sans-serif;
          text-transform: capitalize;
        }
      }
    }

    .customer-detail {
      padding-bottom: 10px;
      border-bottom: 1px solid #E9E9E9;

      .customer-name {
        color: $gray;
        font: normal 500 14px/21px Nunito Sans, sans-serif;
        margin-top: 0;
        margin-bottom: 0;
      }

      .customer-phone {
        color: #707070;
        font: normal 400 12px/16px Nunito Sans, sans-serif;
        margin-top: 0;
        margin-bottom: 0;
      }

      .customer-email {
        color: #707070;
        font: normal 400 12px/16px Nunito Sans, sans-serif;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .tooltip-service-box {
    padding: 0 12px 10px;

    .service-header {
      margin-bottom: 3px;

      .title {
        color: #A4A4A4;
        font: normal 600 10px/15px Nunito Sans, sans-serif;
      }
    }

    .service-detail {
      display: flex;
      justify-content: space-between;
      align-items: start;

      .service-name {
        color: $gray;
        font: normal 500 14px/19px Nunito Sans, sans-serif;
        margin: 0 auto;
      }

      .service-duration {
        color: $gray;
        font: normal 400 12px/18px Nunito Sans, sans-serif;
        margin: 0 auto;
      }

      .service-price {
        color: $gray;
        font: normal 500 14px/19px Nunito Sans, sans-serif;
      }
    }
  }

  .tooltip-notes-box {
    padding: 0 12px 10px;
    border-top: 1px solid #E9E9E9;

    .notes-header {
      margin-bottom: 3px;

      .title {
        color: #A4A4A4;
        font: normal 600 10px/15px Nunito Sans, sans-serif;
      }
    }

    .notes-box {
      .notes-text {
        color: $gray;
        font: normal 600 14px/19px Nunito Sans, sans-serif;
        margin: 0 auto;
      }
    }
  }
}

.fc-event-resizer .fc-event-resizer-end {
  visibility: hidden;
}

.fc-timegrid-col-events {

  .fc-event-resizer-end {
    visibility: hidden;
  }
}