@import "../../../../style/variables";

.verify-email {
  color: $main-blue;
}

.email-check {
  margin: 30px auto !important;

  img {
    display: block;
    width: 100%;
  }
}

.resend-email {
  a {
    text-decoration: underline;
    padding: 0 4px;
  }
}
