@import './_variables';

.MuiAccordion-root:before {
  left: unset !important;
}

.accordion {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  background-color: #F6F6F7!important;
  box-shadow: 1px 5px 24px rgba(68, 102, 242, 0.05);

  @media all and (max-width: 1199px) {
    flex-direction: column;
  }

  @media all and (max-width: 1199px) {
    flex-direction: column;
  }

  @media all and (max-width: 1199px) {
    flex-direction: column;
  }

  .MuiCollapse-hidden {
    visibility: visible!important;

    @media all and (max-width: 1199px) {
      visibility: hidden!important;
    }

  }
  .MuiCollapse-container {
    overflow: visible!important;
  }

  .MuiAccordionSummary-root {
    display: none!important;

    @media all and (max-width: 1199px) {
      display: flex!important;
    }

    &:hover{
      background-color: rgba(0, 120, 255, 0.07);
    }

  }
  .MuiAccordionDetails-root {
    padding: 0!important;
  }

  .MuiAccordion-rounded:first-child {
    flex: 1;
    margin-top: 0!important;

    .MuiTypography-body1 {
      width: 100%;
    }

    @media all and (max-width: 1199px) {
      width: 100%!important;
      .MuiTypography-body1 {
        width: 100%!important;
      }
    }
  }

  .MuiAccordion-rounded:nth-child(2) {
    width: 100% !important;
    flex: 4;
  }
}

.main-message-content {
  height: calc(100vh - 130px);

  img {
    width: 410px;

    @media all and (max-width: 550px) {
      max-width: 320px;
      width: 100%;
    }
  }
}

.main-chat-content {
  overflow: hidden;
  height: calc(100vh - 130px);

  @media all and (max-width: 1199px) {
    height: auto;
  }
}

.accordion-last-item {
  flex: 1;
  overflow: hidden;
}

.menu-item.messages {
  .general-content {

    .users {

      .users-item  {
        border-bottom: 0.5px solid #CBCDCF;
        padding: 11px 24px 12px 24px;
        width: 100%;

        @media all and (max-width: 576px) {
          padding: 11px 24px 12px 24px!important;
        }

        &:active {
          background: rgba(0, 120, 255, 0.07);
          .last-message {
            display: none;
          }

          .user-name {
            align-self: center;
          }
        }
      }

      .user-name {
        a {
          font: 400 14px/25px $main-font;
          color: $black;
        }
      }

      .last-message {
        font: 400 12px/25px $main-font;
        color: #5D5D5D;
        margin-bottom: 0;
        margin-top: -10px;
      }
    }

    .panel {
      .main-content {
        width: 100% !important;

        @media all and (max-width: 1199px) {
          min-height: 0!important;
        }

        @media all and (max-width: 575px) {
          margin-top: 0!important;
        }

        .left-content, .right-content {
          padding: 0;

          .top-content {
            width: 100%;
          }

        }

        .accordion-last-item {
          overflow: auto;

          @media all and (max-width: 1199px) {
            overflow: unset;
          }
        }

        .content-chat {
          width: 100%;
          min-height: calc(100vh - 130px);
          position: relative;

          .chat {
            height: 100%;

            .messages {
              display: grid;
              grid-auto-rows: min-content;
              grid-template-rows: auto;
              align-items: flex-end;
              padding: 29px;
              overflow: auto;
              height: calc(100vh - 335px);

              @media all and (max-width: 576px) {
                height: calc(100vh - 394px);
              }

              .sent-message {
                  margin-left: auto;
                  margin-bottom: 15px;
                  font: 400 14px/25px $main-font;
                  color: $white;
                  list-style-type: none;
                  background: rgba(0, 119, 255, 0.5);
                  border-radius: 8px 8px 0 8px;
                  padding: 9px 15px 10px 15px;
                  max-width: 286px;
              }

              .received-message {
                font: 400 14px/25px $main-font;
                color: $black;
                list-style-type: none;
                background: rgba(202, 202, 203, 0.5);
                border-radius: 8px 8px 8px 0;
                padding: 9px 15px 10px 15px;
                max-width: 286px;
                margin-right: auto;
                margin-bottom: 15px;
              }
            }
          }

          .message-send {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0 29px;
            overflow: auto;

            @media all and (max-width: 991px) {
              bottom: 4%;
            }

            @media all and (max-width: 576px) {
              flex-direction: column-reverse;
            }

            .send-items {
              display: flex;
              margin-right: 17px;
              margin-top: 44px;

              @media all and (max-width: 576px) {
                margin-top: 10px;
              }

              .photo-item {
                margin-left: 19px;
                margin-right: 21px;
              }
            }

            .send-message {
              display: flex;
              max-width: 862px;
              width: 100%;
            }

            .send-btn {
              border: none;
              background: none;
              margin-top: 40px;
            }

            .type-message {
              margin-right: 10px;
              margin-left: 10px;
              min-height: 93px;
              width: 100%;

              textarea {
                overflow: auto;
                resize: none;
                width: 100%;
                height: 100%;
                padding: 15px 13px;
                outline: none;
                border: 1px solid $main-grey;
                border-radius: 5px;
                font: 500 14px/19px $main-font;
                color: $black;
              }
            }
          }
        }

        .no-chat-content {
          text-align: center;
          margin: 20px auto;

            p {
              margin-bottom: 25px;
              font-size: 25px;
              color: #259BDF;
            }
        }

        .chat-content {
          width: 80%;
          min-height: calc(100vh - 130px);

          .top-content {

            .row {
              height: 100%;

              .right-top-item {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid #d0d0d0;
                border-bottom: 1px solid #d0d0d0;

                &:last-child {
                  border-right: none;
                  justify-content: flex-start;
                }

                .main {
                  display: flex;
                  justify-content: space-around;

                  .image-content {
                    display: flex;
                    align-items: center;
                    margin: 8px 12px 8px 0;

                    svg {
                      color: $main-blue;
                    }

                    img {
                      width: 60px;
                      height: 60px;
                      border-radius: 20px;
                    }
                  }
                }
              }
            }
          }

          .chat-content {
            padding-top: 20px;
            max-height: calc(100% - 360px);
            min-height: calc(100% - 360px);
            overflow-y: auto;

            .messages {
              padding: 0 15px;

              .message-item {
                margin-bottom: 15px;

                &.right {
                  justify-content: flex-end;

                  .message-content {
                    box-shadow: 0 3px 6px $black;
                    border-radius: 16px 0 16px 16px;
                    opacity: 1;
                    background: $main-blue;
                    color: #fff;

                    .text {
                      word-break: break-word;
                    }
                  }
                }
              }

              .staff-image {
                width: 44px;
                height: 44px;
                margin-right: 10px;

                img {
                  width: 100%;
                  border-radius: 50%;
                }
              }

              .event-text {
                color: $main-blue;
              }
            }
          }

          .message-submit {
            width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 20px;
            height: 260px;

            textarea {
              width: 100%;
              padding: 10px;
              background: $white 0 0 no-repeat padding-box;
              box-shadow: 0 3px 6px $black;
              border: none;
              border-radius: 16px;
              resize: none;
              color: #B6B8C7;

              &::placeholder {
                color: #B6B8C7;
              }

              &:focus {
                outline: none;
              }
            }

            button {
              align-self: flex-end;
              background: $main-blue;
              border: 0;
              padding: 5px 70px;
              margin-bottom: 15px;
              border-radius: 20px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.content-message {
  margin-bottom: 30px;
  margin-right: 16px;

  .text {
    position: relative;
    box-shadow: 0 3px 6px $black;
    background: #202020;
    border-radius: 8px 8px 8px 0;
    opacity: 0.5;
    word-break: break-word;
    font: 500 14px/25px $main-font;
    padding: 10px;
    color: $white;
  }

  .time {
    text-align: left;
    color: $main-grey;
    font: 400 12px/150% $main-font;
    margin-left: 47px;
  }

}

.message-detail {
  visibility: hidden;
  margin-right: 10px;
}

.content-message:hover {
  .message-detail {
    visibility: visible !important;
  }
}

.message-content:hover {
  .message-detail {
    visibility: visible !important;
  }
}

.message-content {
  margin-bottom: 30px;
  margin-right: 16px;

  .text {
    word-break: break-word;
    font: 500 14px/25px $main-font;
    box-shadow: 0 3px 6px $black;
    background: rgba(0,120,255,.5);
    border-radius: 8px 8px 0 8px;
    padding: 10px;
    color: $white;
  }

  .time {
    text-align: right;
    color: $main-grey;
    font: 400 12px/150% $main-font;
    margin-right: 47px;
  }
}

.message-item.left {
  .staff-image {
    margin-right: 12px;
  }
}

.message-item.right {
  .staff-image {
    margin-left: 12px;
  }
}


.staff-image {
  width: 35px;
  height: 35px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.left-top-content {
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .chat-item-select {

  }
  .icon-link {
    color: #8c949d;
    transition: .5s;
    &:hover {
      color: #1a85ff;
    }
  }
}

.left-content {
  width: 100%;
  min-width: 255px;
  border-right: 1px solid #CBCDCF;
  min-height: calc(100vh - 130px);

  @media all and (max-width: 1199px) {
    min-height: 0;
  }

  .top-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .search {
      margin-bottom: 10px;
      width: 82%;
      position: relative;

      @media all and (max-width: 1199px) {
        width: 50%;
      }

      .search-icon {
        position: absolute;
        top: 17px;
        left: 0;
      }
    }
  }

  .messages-main-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 130px);
    overflow-y: auto;

    .message-item {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      min-height: 72px;
      cursor: pointer;
      border-bottom: 1px solid rgba(0,0,0,0.12);
      padding: 0 12px;

      .image-content {
        display: flex;
        align-items: center;
        margin: 8px 12px 8px 0;

        .user-avatar {
          width: 50px;
          height: 50px;
        }
      }

      .info-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h6 {
          width: 213px;
        }

        span {
          font-size: 11px;
          margin-right: auto;
        }
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.12);

        .info-content {

          h6 {
            color: $main-blue !important;
          }

        }
      }

      .unread-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $main-blue;
        margin-right: 10px;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-top: 20px;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0 3px;
        cursor: pointer;
        color: $main-blue;
        padding: 7px 14px;
        background: #fff;
        box-shadow: 0 3px 6px $black;
        border-radius: 40px;
        font-size: 13px;

        svg {
          width: .5em;
        }

        span {
          padding: 2px 2px;
          margin-top: 1px;
          display: inline-block;
        }

        &.active {
          background: $main-blue;
          color: #fff;
        }

        &.disabled {
          background-color: #cccccc;
          color: #666666;
          cursor: no-drop;
        }
      }
    }
  }
}


.right-content {
  width: 100%;
  border-left: 1px solid #CBCDCF;
  min-height: calc(100vh - 140px);

  @media all and (max-width: 1199px) {
    border: none;
  }

  .more {
    text-align: right;
    padding: 22px 20px 22px 0;
  }

  .details {
    max-width: 294px;
    @media all and (max-width: 1199px) {
      margin: 0 auto;
    }

    .company-image {
      width: 100%;
      margin-bottom: 50px;

      img {
        width: 100%;
        max-height: 200px;
      }
    }

    .about-company {
      display: flex;

      ul:nth-child(1) {
        margin-right: 30px;
      }

      ul {
        padding-left: 22px;

        li {
          list-style-type: none;
          margin-bottom: 28px;
          text-align: left;

          .details-item {
            font: 400 12px/25px $main-font;
            color: $main-grey;
            margin-bottom: 0;;
          }

          .information {
            font: 400 14px/25px $main-font;
            color: $black;
            margin-bottom: 0;
            margin-top: -5px;
          }
        }
      }
    }

    .calendar {
      margin-top: 50px;

      .react-calendar {
        border: none;
      }

      .react-calendar__viewContainer {
        border: 1px solid #D3D1D1;
      }

      .react-calendar__month-view__weekdays {
        border-bottom: 1px solid #D3D1D1;
      }

      .react-calendar__navigation {
        margin-bottom: 0;
        justify-content: center;
      }

      .react-calendar__navigation__label {
        max-width: 116px;
        padding: 0;
      }

      .react-calendar__navigation button {
        color: $main-blue;
        font: 400 14px/11px $main-font;
        padding: 0;
      }

      .react-calendar__month-view__weekdays__weekday {
        color: $main-grey;
        font: 500 10px/4px $main-font;
      }

      .react-calendar__tile {
        color: $black;
        font: 400 10px/13px $main-font;
      }

      .react-calendar__tile.react-calendar__month-view__days__day {
        padding: 8px;
      }

      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button {
        min-width: 0;
      }

      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button,
      .react-calendar__navigation__label,
      .react-calendar__navigation__label,
      .react-calendar__month-view__days__day {

        &:hover {
          background: none;
        }

      }

      .react-calendar__month-view__days__day--neighboringMonth {
        color: $main-grey;
      }

      .react-calendar__tile--active {
        background: #ECF4FF;
      }

      .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
        display: none;
      }
    }
  }
}

.users-detail {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #CBCDCF;
  padding: 0 12px;
  min-height: 72px;

  .user {
    display: flex;
    width: 100%;

    .user-image {
      margin-right: 23px!important;
      align-self: center;
    }

    .user-name {

      a {
        font: 400 16px/25px $main-font;
        color: $black;
      }

      .last-seen {
        font: 400 14px/25px $main-font;
        color: $main-grey;
        margin-bottom: 0;
        margin-top: -8px;
      }
    }
  }

  .details-icon {
    display: none;
    align-self: center;
  }
}

.messages-loading {
  @media all and (max-width: 1199px) {
    display: none !important;
  }
}

.messages-load-small {
  display: none;

  @media all and (max-width: 1199px) {
    display: block;
  }
}

.top-avatar {
  display: flex;
  align-items: center;
  margin: 8px 12px 8px 0;

  .message-avatar {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
  }
}


