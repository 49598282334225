@import "src/style/_variables";

.drawer2 {
  padding-left: 24px;
  padding-right: 24px;

  .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;

    .title {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
    }

    .close-icon {
      cursor: pointer;
      color: $main-grey;
    }
  }

 .content {
   width: 658px;
   height: 100%;
   display: flex;

   .left-content {
     max-width: 260px;
     width: 100%;
     height: 100%;
     border-right: 1px solid #E3E5E6;
     padding-bottom: 24px;

     .left-container {
       display: flex;
       justify-content: space-between;
       flex-direction: column;
       height: 100%;

       .delete-btn-section {
         padding: 0 24px;
       }

       .edit-btn {
         text-align: right;
         padding-right: 24px;
         padding-left: 24px;

         button {
           span {
             color: $gray;
             font: normal 600 14px/19px Nunito Sans, sans-serif;
             margin-left: 8px;
           }
         }
       }

       .detail-section {
         padding-right: 24px;
         padding-left: 24px;

         .avatar-section {
           .avatar {
             display: flex;
             justify-content: center;
             margin-top: 24px;
             margin-bottom: 12px;
           }

           .customer-name {
             color: $gray;
             font: normal 600 16px/22px Nunito Sans, sans-serif;
             text-align: center;
             margin-bottom: 24px;
             margin-top: 0;
           }
         }

         .mail-detail {
           display: flex;
           margin-bottom: 14px;

           .mail-text {
             color: #0282DA;
             font: normal 400 14px/19px Nunito Sans, sans-serif;
             margin-left: 8px;
           }
         }

         .phone-detail {
           display: flex;

           .phone-text {
             color: #393939;
             font: normal 400 14px/19px Nunito Sans, sans-serif;
             margin-left: 8px;
           }
         }
       }

       .divider-border {
          border-bottom: 1px solid #E3E5E6;
          margin: 40px 24px 16px 24px;
       }

       .detail-items {
         padding-right: 8px;
         padding-left: 8px;
       }


       .new-appointment {
         padding-right: 24px;
         padding-left: 24px;

         .new-appointment-text {
           color: #0282DA;
           font: normal 600 14px/19px Nunito Sans, sans-serif;
           margin-left: 8px;
         }
       }

       .delete-btn-text {
         color: #F12B2C;
         font: normal 600 14px/19px Nunito Sans, sans-serif;
         margin-left: 8px;
       }
     }
   }

   .rightContent {
     max-width: 398px;
     width: 100%;
     display: flex;
     flex-direction: column;
     height: 100%;
     justify-content: space-between;

     .editCustomerText {
       color: $gray;
       font: normal 700 16px/19px Nunito Sans, sans-serif;
       margin: 0 24px 24px;
     }

     .MuiDrawer-paperAnchorRight {
       flex-direction: column;
       justify-content: space-between;
     }

     .drawer-content {
       padding-left: 24px;
       padding-right: 24px;

       .dropzone {
         position: relative;
         width: 100px;
         height: 100px;
         margin: 0 auto 30px;
         cursor: pointer;

         .image {
           width: 100px;
           height: 100px;
           border-radius: 50px;
         }

         .action {
           position: absolute;
           bottom: 0;
           right: 0;
           width: 32px;
           height: 32px;
           border-radius: 50px;
           background: #A2D1F2;
           border: 2px solid #FFFFFF;
           display: flex;
           align-items: center;
           justify-content: center;

           svg {
             color: white;
           }
         }
       }

       .editStaffTitle {
         color: $gray;
         font: normal 700 16px/19px Nunito Sans, sans-serif;
         margin: 0 0 20px 0;
       }

       .input-content {
         margin-bottom: $mediumMargin;

         .email-bottom-text {
           margin-top: 8px;
           margin-bottom: 0;
           color: #A4A4A4;
           font: normal 400 12px/16px Nunito Sans, sans-serif;
         }

         .MuiInputLabel-outlined.MuiInputLabel-marginDense {
           transform: translate(14px, 18px) scale(1) !important;
         }

         .MuiInputLabel-outlined.MuiInputLabel-shrink   {
           transform: translate(14px, -6px) scale(0.75) !important;
         }

         .input {
           width: 100%;
           border-color: $main-grey;
           border-radius: 4px;
           font-size: 16px;

           input {
             font-size: 15px;
           }

           .MuiInputBase-root {
             height: 48px;
           }
         }

         .label {
           margin-bottom: 8px;
           font-weight: 400;
           font-size: 16px;
           color: $gray;
         }
       }
     }

     .save-btn {
       display: flex;
       justify-content: end;
       align-items: center;
       gap: 8px;
       padding: 20px;

       button {
         width: 100%;
       }
     }
   }
 }
}

.appointments-section {
  .appointments-pagination {
    display: flex;
    flex-direction: column;
    height:   calc(100vh - 173px);
    justify-content: space-between;
  }

  .appointment-date {
    color: $gray;
    font: normal 600 14px/22px Nunito Sans, sans-serif;
  }

  .appointment-box {
    width: 100%;
    height: 65px;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;

    .statusBg {
      border-radius: 4px 0 0 4px;
      position: absolute;
      width: 8px;
      height: 100%;
      left: 0;
    }

    .box-content {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 24px;

      .time-box {
        border-right: 0.5px solid #A4A4A4;
        padding-right: 16px;

        p {
          margin-top: 0;
          margin-bottom: 0;
          color: $gray;
          font: normal 600 14px/19px Nunito Sans, sans-serif;
        }
      }

      .detail-box {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;

        .status {
          span {
            font: normal 600 10px/15px Nunito Sans, sans-serif;
            text-align: right;
            position: absolute;
            right: 16px;
            top: 4px;
          }
        }

        p {
          margin-top: 0;
          margin-bottom: 0;
        }

        .service-name {
          color: $gray;
          font: normal 600 14px/19px Nunito Sans, sans-serif;
        }

        .staff-name {
          color: #323232;
          font: normal 400 12px/16px Nunito Sans, sans-serif;
        }
      }
    }
  }
}

.no-avatar-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-right: 12px;
  border-radius: 50px;
  background: #F2F9FD;
  border: none;
  color: #A2D1F2;
  font: normal 700 48px/65px Nunito Sans, sans-serif;
}

.MuiTablePagination-root {
  border-top: 1px solid #E3E5E6;
}