@import "../../../../../style/_variables";

#reminders {
  .dropdown-toggle {
    width: 256px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 16px;
    box-shadow: 0 3px 6px $black;
    border-radius: 16px;
    background: $main-blue;
    border: none;
  }

  .appointments-reminder-content {
    padding: 40px 0;

    .reminder-block {
      position: relative;
      margin-bottom: 65px;

      .reminder-bg {
        height: calc(100% + 30px);
        align-items: center;
        position: absolute;
        top: 0;
        left: 40px;

        .circle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $main-blue;
        }

        .line {
          height: calc(100% - 20px);
          width: 1px;
          background-color: $main-blue;
        }
      }

      .title {
        color: $main-blue;
        font-size: 16px;
        margin-bottom: 10px;
        margin-left: 60px;
      }

      .reminder-item {
        box-shadow: 0 3px 6px $black;
        height: 78px;
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 16px;
        line-height: 25px;
        color: #A8AABC;
        margin-bottom: 15px;
        position: relative;
        background-color: #fff;
        z-index: 2;

        &.checked {
          color: $main-blue;
        }

        .actions {
          span {
            color: #A8AABC;
            font-size: 24px;
          }
        }
      }
    }
  }

  .reminder-modals {
    .modal-dialog {
      max-width: 60%;
    }

    .modal-content {
      border-radius: 16px;
      padding: 50px 40px;

      .modal-title {
        font-size: 28px;
        color: $main-blue;
        margin-bottom: 35px;
        font-weight: 500;
        text-align: left;
      }

      .modal-body {
        .form-group {
          margin-bottom: 25px;

          label {
            color: $main-blue;
            margin-bottom: 10px;
            font-size: 16px;
          }

          .form-item, .form-select {
            box-shadow: 0 3px 6px $black;
            border-radius: 16px;
            height: 42px;
            border: none;

            .css-yk16xz-control,
            .css-1pahdxg-control {
              height: 100%;
              border: none;
              border-radius: inherit;
            }
          }

          textarea.form-item {
            height: 144px;
            padding-top: 10px;
            resize: none;
          }
        }
      }
    }

    &.modal.fade.show {
      margin-top: unset;
    }
  }
}
