#dropzone {
  .dropzone-container {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: sans-serif;
    padding: 0;
  }

  .dropzone-container > p {
    font-size: 1rem;
  }

  .dropzone-container > em {
    font-size: .8rem;
  }

  .dropzone {
    min-width: 150px;
    max-width: 150px;
    height: 150px;
    background-image: url("../assets/img/add-img.png");
    background-color: #e5e5e5;
    background-origin: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 3em;
    //margin: 10px;

    &:focus, &:hover {
      border-color: #2196f3;
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.6;
    }
  }
}
