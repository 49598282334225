@import "../../../style/_variables";

#header {
  background: #e4ebf1;

  .roles {
    display: flex;

    .role {
      padding: 10px 30px;
      color: $black;
      background: $white;
      cursor: pointer;
      font-weight: bold;

      &.selected {
        background: #1a85ff;
        color: white;
      }
    }
  }
}
