@import "../../../../../style/variables";

#appointmentContainer {

  .categoriesContainer {
    height: 100%;
    border-right: 1px solid #E3E5E6;

    .titleBox {
      padding: 22px;

      p {
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $gray;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .categories {
      padding: 0 8px;
      max-height: calc(100% - 66px);
      overflow-y: auto;
    }
  }

  .tableBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 129px);
    border-top: 1px solid #E9E9E9;

    .table-row {
      color: red;
    }
  }
}

#headerContainer {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 24px;

    .searchBox{
      max-width: 250px;
      width: 100%;
    }
  }
}

.fromText {
  color: #A4A4A4;
  font: normal 500 14px/19px Nunito Sans, sans-serif;
}

.buttonBox {
  height: 39px;

  button {
    height: 100%;
    padding: 4px 16px;
    font: normal 500 14px/19px Nunito Sans, sans-serif;
  }
}

.serviceModal {
  .content {
    .imgBox {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }

    .contentText {
      font: normal 400 14px/19px Nunito Sans, sans-serif;
      color: $gray;
      text-align: center;
      margin-bottom: 0;
    }
  }
}