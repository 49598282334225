@import 'variables';

.main-content {

  .notifications-item {
    padding: 15px 0;
    border-bottom: 2px solid #e4ebf1;

      &.unread {
        background: #edf2fa;
      }

      .notification-content {
        font-size: 22px;

        .calendar {
          border-radius: 50%;
          margin-top: 5px;
          background-color: #ecb900;
          color: #fff;
          padding: 6px 10px !important;
        }

        .activity-content {
          display: flex;
          align-items: center;
          padding: 0 10px;
          position: relative;

            .notification-message {
              margin: 0 10px;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }

            .notification-status {
              color: white;
              font-size: 17px;
              background: gray;
              padding: 0 5px;
              border-radius: 10px;
              border: 1px solid black;
              margin: 0 15px;
            }

            .notification-type {
              color: white;
              margin-left: 10px;
              padding: 3px 10px;
              border-radius: 10px;
              border: none;

                &.accepted {
                  background: #c1ff5d;
                }

                &.created {
                  background: #5dffd9;
                }

                &.cancelled {
                  background: #ff5d70;
                }
            }

            .span-time {
              text-align: end;
              flex-grow: 20;
              font-size: 15px;
            }

            .activity-buttons {
              flex-grow: 4;
              text-align: end;

              button {
                width: 100px;
                color: #fff;

                &.accept {
                  background-color: $main-blue;
                }

                &.cancel {
                  background-color: #cdd4da;
                }
              }
            }
        }
      }
  }
}

.notifications {
  .content {
    height: 100% !important;
    min-width: 426px;

    @media all and (max-width: 350px){
      min-width: 270px;
    }
  }
}

.notification-title {
  font: normal 600 14px/19px $backup-font;
  color: $gray;
  margin: 0;
}

.notification-option-menu {
  .MuiMenu-paper {
    left: unset !important;
    right: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #FFFFFF;
  }
}

.notification-accepted {
  font: normal 400 14px/19px $backup-font;
  color: $gray;
  margin: 0 0 5px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.accepted-time {
  font: normal 400 12px/16px $backup-font;
  color: #707070;
}

.menu-item {
  padding: 0;
}

.menu-delete-btn {
  color: #F12B2C;
  font: normal 400 16px/20px Nunito Sans, sans-serif;
  margin-left: 12px;
}

.see-all {
  justify-content: center !important;
  color: $main-blue !important;
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex !important;
  margin: 0 auto !important;
  font: normal 600 14px/19px $backup-font;
  padding: 7px 10px !important;
}

.buttons-notification {
  width: 100%;
  max-height: 700px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  &:focus {
    outline: none;
  }
}


.show-less-box {
  background: #FFFFFF;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.10);
  border-radius: 0 8px 8px 8px;
}

.buttons-notification::-webkit-scrollbar {
  display: inherit;
}

.buttons-notification.show-more {
  overflow: auto;
  max-height: 680px;
}
.buttons-notification.show-less{
  overflow: hidden;
  max-height: 430px;
}

.done-icon {
  align-self: center;
}

.item-box {
  display: flex;
  gap: 15px;

  .seen-avatar-box {
    display: flex;
    align-items: center;
    gap: 7px;
  }
}

.notification {
  max-width: 500px;
  width: 100%;
  max-height: 80vh;
  z-index: 99;

  .notification-content {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }
}

.notification-item {
  padding: 10px 10px 0 10px;
  position: relative;

  &.unread {
    .unread-notification {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $main-blue;
    }
  }

  &:hover {
    background-color: #e5f1ff;
    cursor: pointer;
  }

  .content {
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 10px;
  }

  .notification-buttons {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    padding: 0 16px;

    .cancel-btn {
      border: 1px solid #A4A4A4;
      border-radius: 4px;
      width: 100px;
      height: 36px;
      color: #A4A4A4;
      font: normal 500 14px/19px Nunito Sans, sans-serif;
      background-color: $white;
    }

    .accept-btn {
      border-radius: 4px;
      width: 100px;
      height: 36px;
      color: $white;
      background-color: #0282DA;
      font: normal 500 14px/19px Nunito Sans, sans-serif;
    }
  }
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .title-box {
    p {
      color: #393939;
      font: normal 700 16px/22px Nunito Sans, sans-serif;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .read-all-box {

    a {
      color: #0282DA;
      font: normal 600 14px/19px Nunito Sans, sans-serif;
      cursor: pointer;
    }
  }
}
