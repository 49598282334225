@import '../../../../../../style/variables';

.active-title {
  display: flex;
  justify-content: space-between;
  padding: 19px 20px 19px 20px;
  background: #fff;
  z-index: 100;
  width: calc(100% - 250px);
  left: 250px;
  @media (max-width: 767px) {
    width: 100%;
    left: 0;
  }
}

#outlined-textarea {
  min-height: 128px;
  max-height: 128px;
}

//CUSTOMER
.line-customers {
  background-color: $white;
}
.customers {
  .main-content {
    min-height: 0!important;
    height: 100%!important;
  }
}

// WORKING SCHEDULE
.working-hours, .breaking-hours {
  border: 1px solid #CBCDCF;
  padding: 17px 22px 3px 17px;
  overflow: auto;
}
.title-hours {
  margin-bottom: 45px;
  text-align: center;
  @media (max-width: 1350px) {
    margin: 45px auto 45px auto;
  }
  p {
    font: 500 16px/25px $main-font!important;
    color: $black!important;
  }
}

.day-name {

  span {
    font: 400 16px/25px $main-font;
    color: $black;
  }

}

.hours-select {
  display: flex;
  justify-content: center;
  .border-to {
    border-top: 1px solid #CBCDCF;
    width: 10px;
    align-self: center;
    margin-right: 4px!important;
    margin-left: 4px!important;
  }
}

.tab-list {
  border-radius: 5px;
  height: 100%;

  .list-tabs {
    display: flex;
    justify-content: center;
    margin: 0;
    @media all and (max-width: 1350px) {
      flex-direction: column;
      padding-bottom: 45px;
    }
  }
  .item-working {
    max-width: 408px;
    width: 100%;
    overflow: scroll!important;
    @media all and (max-width: 1350px) {
      margin: 0 auto;
    }
    .working-hours {
      min-width: 408px;
    }
  }
  .week-day-switch {
    margin: 0;
    align-self: center;
  }

  .tab-item {
    justify-content: center;
    border-radius: 4px;
    overflow: auto;

    .business-hours {
      margin: 0 auto 0!important;
      width: 100%;
    }
  }
}

.day-content {
  justify-content: center;

  .hours-select {
    height: 30px;

    span {
      margin: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #CBCDCF;
      font-size: 20px;
    }
  }
}


.selects-border {
  border-bottom: 1px solid $main-grey;
  width: 50%;
  margin: 0 auto;
}

.day-content {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 30px;
}
