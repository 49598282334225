@import '../../../../../style/_variables';

.menu-item.create-appointment {
  width: 100%;
  background: #eee;
  font-family: 'Poppins', sans-serif;

  .general-content {

    .panel {

      .main-content {

        .loading-container {
          display: flex;
          align-items: center;
          height: 75%;
          justify-content: center;
        }

        .nav-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 25px;

          .navigation-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 16%;
            cursor: pointer;
            padding: 20px 0 0 0;

            &.active {
              border-bottom: 3px solid $main-blue;

              .item-count {
                transition: background 0.5s ease;
                background: $main-blue;
                color: #fff;
              }

              .item-name {
                transition: color 0.5s ease;
                font-size: 20px;
                color: $main-blue;
              }
            }

            &.passed {
              border-bottom: 3px solid $main-blue;

              .item-count {
                background: #fff;
                color: $main-blue;
              }

              .item-name {
                margin-top: 15px;
                color: $main-blue;
              }
            }

            .item-count {
              background: #fff;
              color: #a8aabc;
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              box-shadow:1px 1px 3px 1px rgba(0, 0, 0, 0.2);

              &.first {
                padding-right: 1px;
              }
            }

            .item-name {
              margin-top: 10px;
              color: #a8aabc;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 80%;
              overflow: hidden;
            }
          }
          .navigation-item-individual {
            width: 22%;
          }

          .circle-container {
            width: 12%;
            display: flex;
            justify-content: center;
            padding: 37px 0;

            &.active {
              border-bottom: 3px solid $main-blue;

              .circle-separator {
                background: $main-blue;
              }
            }

            .circle-separator {
              width: 20px;
              height: 20px;
              background: rgb(182, 184, 199);
              border: 1px solid rgb(182, 184, 199);
              border-radius: 10px;
              box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
            }
          }
          .circle-container-individual {
            width: 17%;
          }
        }

        .industries-tab {

          .industries-content {
            margin-top: 25px;

            .industry-item-container {
              display: flex;
              flex-direction: column;

              .industry-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 42px;
                padding: 0 20px;
                margin-bottom: 25px;
                box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
                border-radius: 20px;
                cursor: pointer;

                .industry-name {
                  font-size: 18px;
                  margin-left: 20px
                }
              }

              .industry-services-content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .service-item  {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 96%;
                  height: 42px;
                  padding: 0 20px;
                  margin-bottom: 25px;
                  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
                  border-radius: 20px;
                  cursor: pointer;

                  .service-name {
                    font-size: 18px;
                    margin-left: 20px
                  }

                  .image-content {

                    img {
                      width: 20px;
                    }
                  }
                }
              }
            }
          }
        }

        .companies-tab {
          .companies-content {
            margin-top: 25px;

            .company-item-container {
              position: relative;

              .company-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 42px;
                padding: 0 20px;
                margin-bottom: 25px;
                box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
                border-radius: 20px;
                cursor: pointer;

                .logo-content {
                  img {
                    width: 25px;
                    border-radius: 20px;
                  }
                }

                .item-name {
                  font-size: 18px;
                  margin-left: 20px
                }
              }

              .company-details {
                transition: 1s all ease;
                margin-top: 18px;

                .header {
                  align-items: center;
                  margin: 0 0 42px 0;

                  .image-content {

                    img {
                      width: 90px;
                      border-radius: 50px;
                    }
                  }

                  .name-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: 16px;

                    h3 {
                      align-self: flex-start;
                    }

                    p {
                      color: #959595;
                    }
                  }
                }

                .content {
                  margin-bottom: 42px;

                  .content-item {
                    padding: 0 20px;

                    &:first-child {
                      padding-left: 0;
                      padding-right: 40px;
                    }

                    &:last-child {
                      display: flex;
                      flex-direction: column;
                      padding-left: 40px;
                      padding-right: 0;

                      .contact-info-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 36px;
                        height: 100%;
                        justify-content: space-between;
                      }
                    }

                    .title-container {
                      display: flex;
                      justify-content: center;
                      border-bottom: 1px solid #d4d4d4;

                      h5 {
                        font-weight: 500;
                      }
                    }

                    .contact-details-content {
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;

                      .nav-headers {
                        display: flex;
                        flex-direction: column;
                        padding: 0 40px;
                        color: $main-blue;

                        span {
                          &:not(:first-child) {
                            padding: 10px 0;
                          }

                          &:first-child {
                            padding-bottom: 10px;
                          }
                        }
                      }

                      .nav-values {
                        display: flex;
                        flex-direction: column;

                        span {

                          &:not(:first-child) {
                            padding: 10px 0;
                          }

                          &:first-child {
                            padding-bottom: 10px;
                          }
                        }
                      }
                    }

                    .details-content {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      margin-top: 36px;

                      .gmap_canvas {
                        padding-top: 10px;
                      }

                      .working-hour-item {
                        display: flex;
                        align-items: center;
                        width: 60%;
                        justify-content: space-between;

                        .week-day {
                          width: 110px;
                          margin: 10px 0;
                          padding: 5px 10px;
                          border-radius: 17px;
                          display: flex;
                          justify-content: center;
                          font-size: 14px;
                          box-shadow: 0 1px 7px 1px rgba(0, 0, 0, 0.2);

                          &.today {
                            background-color: $main-blue;
                            color: white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .staff-tab,
        .individual-users-tab {

          .staff-content,
          .individual-user-content {
            padding: 36px 0;
            border-top: 2px solid #bebebe;

            &:first-child {
              margin-top: 44px;
            }

            .staff-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .left-content {

                .image-content {

                  img {
                    width: 80px;
                    border-radius: 40px;
                  }
                }

                .info-content {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-left: 16px;

                  h3 {
                    font-weight: 400;
                    align-self: start;
                  }

                  p {
                    color: #959595;
                    align-self: start;
                  }
                }
              }

              .right-content {
                .first-available-time {
                  font-size: 28px;

                  span {
                    margin-left: 15px;
                  }
                }
              }
            }


            .staff-details,
            .individual-user-details {
              margin-top: 65px;
              padding-bottom: 50px;

              .month-content {
                 .react-calendar {
                   border: none;
                   font-family: "Poppins", sans-serif;

                   .react-calendar__navigation {
                     align-items: center;

                     .react-calendar__navigation__arrow {
                       min-width: 30px;
                       height: 30px;
                       border-radius: 50%;
                       background: #fff;
                       box-shadow: 0 6px 16px $black;
                     }
                   }

                   .react-calendar__navigation__prev2-button,
                   .react-calendar__navigation__next2-button {
                     display: none;
                   }

                   .react-calendar__navigation__label {
                     border: none;
                     color: $main-blue;
                     font-family: Poppins, sans-serif;
                     font-size: 24px;
                     width: auto;
                   }

                   .react-calendar__month-view__weekdays__weekday {
                     text-transform: none;
                     max-width: 40px !important;
                     margin: 0 6px 6px 0;

                     abbr {
                       font-size: 14px;
                       text-decoration: none;
                       font-weight: normal;
                       color: #000;
                     }
                   }

                   .react-calendar__month-view__days__day {
                     font-size: 14px;
                     border-radius: 10px;
                     max-width: 40px !important;
                     height: 40px;
                     padding: 0;
                     margin: 0 6px 6px 0;
                     box-shadow: 0 3px 3px $black;
                     color: $main-blue;
                   }

                   .react-calendar__month-view__days__day--weekend {
                     background-color: unset;
                     color: #AFAFAF80;
                     box-shadow: unset;
                   }

                   .react-calendar__tile--active {
                     background-color: $main-blue;
                     color: #fff;
                   }

                   .react-calendar__navigation__label {
                     outline: none;
                     background-color: unset;
                   }
                 }
               }

              .set-time {
                width: 90%;

                img {
                  margin-bottom: 17px;
                }

                .hours-content {
                  max-width: 295px;

                  .item {
                    cursor: pointer;
                    width: 60px;
                    height: 35px;
                    border-radius: 16px;
                    box-shadow: 0 3px 6px $black;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    display: inline-flex;
                    margin: 10px 17px;

                    &.chosen {
                      background-color: $main-blue;
                      color: #fff;
                    }

                    &.disabled:not(.chosen) {
                      background: #f0f0f0;
                      cursor: no-drop;
                    }
                  }
                }

                .carousel-item {
                  align-items: center;
                  justify-content: center;
                  opacity: 0;
                  transition: transform .3s ease;

                  &.active {
                    opacity: 1;
                    display: flex;
                  }
                }

                .carousel-inner {
                  display: flex;
                  min-height: 118px;
                  min-width: 250px;
                  width: 75%;
                  margin: 0 auto;
                }

                .carousel-indicators {
                  display: none;
                }

                .carousel-control-next,
                .carousel-control-prev {
                  top: 37%;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  background: #fff;
                  box-shadow: 0 6px 16px $black;

                  .carousel-control-prev-icon {
                    margin-top: 2px;
                    margin-right: 3px;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 10'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
                  }

                  .carousel-control-next-icon {
                    margin-top: 2px;
                    margin-left: 3px;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 10'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
                  }
                }
              }

              .notes-content {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                &.button-added {
                  justify-content: space-between;
                  height: 100%;

                  .area {
                    padding-bottom: 60px;
                    margin-top: 0;
                  }
                }

                .title-content {
                  display: flex;
                  justify-content: center;
                  width: 80%;
                  border-bottom: 2px solid #d4d4d4;

                  .name {
                    font-size: 24px;
                    margin-bottom: 9px;
                  }
                }

                .area {
                  margin-top: 35px;
                  width: 80%;

                  textarea {
                    width: 100%;
                    resize: none;
                    padding-left: 16px;
                    padding-top: 10px;
                    border: none;
                    background: $white 0 0 no-repeat padding-box;
                    box-shadow: 0 3px 6px $black;
                    border-radius: 16px;

                    &:focus {
                      outline: none;
                    }
                  }
                }

                .make-appointment {
                  align-self: flex-end;
                }
              }
            }
          }
        }
      }
    }
  }
}
