@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap');

@import "../../../../../style/_variables";

.show-logo-content {
  position: relative;

  .image-mask {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .image-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: calc(100% - 15px);
    height: 100%;
    background: rgba(0, 0, 0, .5);
  }
}

.card-plus-box {
  margin: 15px;
  border: 1px solid #ececec;
  height: 295px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px !important;
  background-image: url("../../../../../assets/img/add-plus-company.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5em;
  z-index: 100;

  @media all and (max-width: 768px) {
    display: none;
  }

  &:hover {
    box-shadow: rgba(91, 95, 99, 0.25) 0 25px 50px -12px !important;
    cursor: pointer;
  }
}

.staff-box {
  &:hover {
    cursor: pointer;
  }
}

.staff-fields,
.customer-fields,
.appointments-fields {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .search-staff,
  .search-customer {
    margin-right: 20px;
    svg {
      color: #8c949d;
    }
  }
}

.card-box-root {
  height: 295px;
  .MuiBox-root {
    &:hover {
      cursor: pointer;
    }
  }
}

.customized-dialog-content {
  .MuiTab-fullWidth {
    flex-basis: auto;
  }

  #staff-tab-panel .MuiGrid-container {
    min-height: 230px;
    position: relative;
    padding: 5px;
    width: 100%;
    margin: 0;
  }

  .staff-tab-card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 250px;
    padding: 10px 0;

    h6 {
      margin-bottom: 10px;
    }
    h5 {
      margin: 10px 5px 10px 0;
    }
    p {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 0;
    }

    .history-container {
      box-shadow: none;

      th {
        text-align: center;
        background-color: $white;
      }

      td {
        font-size: 14px;
        text-align: center;
      }

      .name {
        display: block;
        white-space: nowrap;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .services-container {
      box-shadow: none;
      th {
        background-color: $white;
      }

      tbody td {
        font-size: 14px;
      }
    }

    .card-box-avatar {
      width: 140px;
      height: 140px;
      font-size: 3em;
    }

    .staff-details-item {
      margin-top: 10px;
      font-size: 13px;
      font-weight: normal;
    }

    .staff-details-info {
      font-size: 16px;
      font-weight: bold;
      text-transform: capitalize;

      li {
        list-style: none;
      }
    }
  }

  .staff-tab-card.MuiPaper-elevation1 {
    box-shadow: none;
  }
}

.card-header-service.accordion-category {
  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }
}

.appointment-status {
  text-align: center;
  padding: 4px 0;
  color: white;
  border-radius: 3px;

  &.s_2 {
    background-color: $main-orange;
  }

  &.s_4 {
    background-color: $main-blue;
  }

  &.s_3 {
    background-color: #969696;
  }

  &.s_1 {
    background-color: $main-green;
  }
}

.customer-box {
  button {
    z-index: 1000;
  }
}

.companies-tab-list {

  h6 {
    font-size: 15px;
    margin-bottom: 15px;
  }

  textarea.overview {
    max-width: 100%;
    min-width: 100%;
    max-height: 20vh;
    min-height: 15vh;
  }
}

.customized-dialog-content {
  .MuiTab-fullWidth {
    flex-basis: auto;
  }

  .customer-tab-card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 240px;
    .history-container {
      width: 100%;
      margin-top: 0;
      margin-bottom: 20px;
    }

    .card-info {
      box-shadow: none;

      h5 {
        margin: 10px 5px 10px 0;
      }
      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0;
      }
    }

    .card-box-avatar {
      width: 140px;
      height: 140px;
      font-size: 3em;
    }

    th.MuiTableCell-stickyHeader {
      text-align: center;
      background-color: $white;
    }

    tbody.history-body {
      td {
        font-size: 14px;
        text-align: center;
      }
    }

    .name {
      display: block;
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .no-staff-information {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #customer-tab-panel .MuiGrid-container {
    position: relative;
    padding: 0;
    width: 100%;
    min-height: 230px;
    margin: 0;

    .card-info {
      padding: 30px 20px 0 20px;
    }
  }
}

.customer-tab-card.MuiPaper-elevation1 {
  box-shadow: none;
}

.card-action-area.MuiCardContent-root {
  width: 100%;
  padding: 0;
}

.card-action-area.MuiCardContent-root:last-child {
  padding-bottom: 0;
}

.preview-email {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.staff-preview-name {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}


.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
  opacity: 0 !important;
}

.service-icon {
  width: 22px;
  height: 20px;
}

.disable-industries * {
  cursor: no-drop;
  background: #fff;
  border-radius: 10%;

  .industries * {
    height: auto;
    color: #d5d5d5;
  }
}

// Invite Staff modal text
.invite-staff-text,
.invite-customer-text {
  font-size: 15px;
  font-weight: 400;

  .invite-staff-text-name,
  .invite-customer-text-name {
    color: #1a85ff;
  }
}

.turn-off {
  cursor: no-drop !important;
  filter: opacity(0.5);
  background-color: #e5e5e5 !important;
  color: #000 !important;

  &::before {
    left: unset !important;
    background-color: #ccc !important;
  }
}

.company-main-content {
  .company-name-content {
    font-size: 14px;
    color: #1E140A;
    font-weight: bold;
    margin-top: 14px;
  }
}

#customized-menu {
  ul li {
    padding: 0 20px;
  }
}

.active-card-box {
  padding: 0 !important;
  text-align: center;

  .MuiList-root.MuiMenu-list.MuiList-padding {
    width: 120px !important;
  }

  .active-menu-btn {
    padding: 2px 10px;
    background-color: $white;
    border: 1px solid $main-blue;
    color: $main-blue;
    box-shadow: none;
    width: 120px;
    height: 30px;

    &:hover {
      color: $white;
      background-color: $main-blue;
    }
  }
}

.content-card-box {
  padding: 8px 12px 8px 16px !important;
  text-align: center;

  .invited-select {
    margin-top: 10px;
  }

  .invite-btn,
  .invite-menu-btn,
  .invited-menu-btn {
    border: 1px solid #006ce5;
    margin: 13.5px 0;
    padding: 2px 30px;
    background-color: #3486e3;
    color: $white;
    &:hover {
      background-color: #62B1E8;
      border: 1px solid #62B1E8;
    }
  }
  .invited-menu-btn {
    padding: 2px 10px;
    background-color: $white;
    color: #1a85ff;
    box-shadow: none;
    &:hover {
      color: $white;
      background-color: #3486e3;
    }
  }

  &:last-child {
    padding-bottom: 8px !important;
  }

  .registered-icon {
    font-size: 16px;
    opacity: .5;
    margin: 15.5px 0;
  }

  .resend-text {
    font-size: 14px;
    margin: 5px 0 0 0;
  }
}

.staff-tab-loading.img-header {
  margin-top: 30px;
}

.img-header {
  .MuiBadge-badge {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-color: #006ce5;
    background-color: transparent;
    svg {
      font-size: 2.5em;
      color: #0282DA;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 0 0 #0282DA;
    }
  }
}

.add-logo-content#dropzone .dropzone {
  //margin-left: 17px;
  //margin-right: 10px;
  padding: 25px !important;
  height: 165px;
  background-image: url("../../../../../assets/img/add-img.png");
  background-color: #e5e5e5;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3em;
}

.add-logo-content {
  .removed-logo {
    display: none !important;
  }
}

.add-gallery-content#dropzone .dropzone {
  min-width: 150px;
  max-width: 160px;
  height: 160px;
  background-image: url("../../../../../assets/img/add-img.png");
  background-color: #e5e5e5;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3em;
  margin: 10px;
}

.thumb-style div {
  width: 100%;
  height: 100% !important;
}

.company-link svg,
.company-email svg {
  opacity: .3;
}

.thumb-style .remove-industry-avatar svg {
  position: absolute;
  right: 0;
}

.thumb-style-logo div {
  padding: 0 !important;
  height: 165px;
}

#mui-component-select-phone_code {
  display: flex;
}

#mui-component-select-phoneCode {
  display: flex;
}

.country-flag {
  margin-right: 5px;
}

.settings-box {
  display: flex;
}

.settings-calendarBox {
  margin-bottom: 20px;
  padding: 16px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
}

#settings-followBox {
  padding: 0;
}

.settings-appointmentBox {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.settings-appointments {
  margin: 16px 0;
}

.category-rows {
  max-height: calc(100vh - 64px - 65px - 64px - 48px);
  overflow-y: auto;
}

.company-gallery {
  margin: 22px;

  section.gallery {
    display: flex;
    justify-content: start;
  }

  .aside {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.thumbGallery {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: 10px;
  width: 160px;
  height: 160px;
  padding: 4px;
  box-sizing: border-box;
  .thumbInner {
    position: relative;
    display: flex;
    min-width: 0;
    overflow: hidden;
  }
  .img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.error-content {
  display: flex;
  width: 550px;
  justify-content: space-between;
}

.add-logo-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;

  .rect-shape {
    border-radius: 2px;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;

    &.switch {
      height: 80px;
      width: 42%;
    }

    img {
      border-radius: 50%;
      border: 1px solid #efefef;
      height: 80px;
    }
  }
}

.top-bar-personal {

  .dropdown {
    .btn.actions {
      border-radius: 5px;
    }
  }
}

.staff-section {
  margin-top: 10px;
  margin-left: 15px;
}

.staff-section.col-auto {
  display: flex;
  padding: 0 !important;
}
.create-staff-modal-dialog {
  max-width: 500px;
}
.create-staff-modal-dialog {
  .btn {
    height: 40px;
    border-radius: 5px;
  }

  .css-2b097c-container {
    margin-bottom: 10px;
  }

  .table th {
    font-size: 13px;
    color: #555;
  }
}

.active-staff {
  width: 60px;
  background-color: $main-blue;
  border: 1px solid $main-blue;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: white;
  }
}

.edit-permissions-modal-dialog {
  max-width: 500px !important;

  .modal-header {
    h4 {
      font-size: 20px;
      margin: 0;
    }
  }

  .modal-body {
    padding: 0 1rem;
  }

  table {
    tr th {
      font-size: 15px;
      color: #333;
      width: 50%;
      border-top: none;

      div {
        font-size: 14px;
      }
    }
  }

  .modal-footer .btn {
    width: 110px;
  }
}

.modal-footer {
  border-top: none !important;
}

.top-bar-actions {
  width: 100%;
  z-index: 100;

  .action-content {
    .filter-select {
      width: 13%;
      margin: 0 20px;
    }

    .actions-tab {
      button.actions {
        width: 100%;
        height: 40px;

        &:after {
          display: none;
        }
      }
    }
  }
}

.permission-name {
  display: inline-block;
  width: 120px;
}

.col-auto.customers-line {
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;

  .customer-item-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: $main-blue;
    padding: 5px 0 0 0;
  }

  .customer-item {
    .actions-dropdown {
      position: absolute;
      top: 5px;
      right: 15px;

      .dropdown-toggle {
        flex-direction: row;
        align-items: center;

        &:before, i {
          margin-right: 2px;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.action-btn.customer-link {
  color: $black;

  &:hover {
    color: #1a85ff;
  }
}

.filter-date {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.modal-body {
  padding: 0 5px 5px 10px !important;
}

.error-message {
  position: relative !important;
}

.dropdown-item p {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.filter-menu {
  right: 0;
  width: 16%;
}

.closed {
  color: $main-grey;
  margin-left: 15px;
}

#info-tab {
  margin: unset;
  max-width: 1000px;
  width: 100%;
  padding: 25px;
  border-radius: 2px;

  .business-details {
    width: 100%;
  }

  .form-group {
    margin: 0;
  }

  .form-column-select {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;

    .select-content {
      .form-item-select {
        height: 100%;
      }
    }
  }
}

#working-schedule-content {
  padding: 32px;
  width: 100%;
  border-radius: 2px;
  margin: 0;
}
.general-schedule {
  margin-top: 20px;
}
#working-schedule-content {
  #tableTitle {
    margin-left: 10px;
    padding: 10px 0;
  }
}
.working-schedule-box {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 10px 10px ;
  margin-bottom: 10px;
}
.working-schedule-title {
  border-bottom: 1px solid lightgray;
}
.company-box {
  padding: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.staff-box {
  padding: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.company-staff-date {
  #company {
    padding-right: 10px;
  }
}

.staff-box,
.breaking-schedule,
.working-schedule {
  margin-top: 2px;
}

.button-grid-cont {
  max-height: 84px;
}

.hours-select select {
  width: 85px;
}

@media (max-width: 1100px) {
  #tab-main-parent {
    width: 100%;
  }
}

@media all and (max-width: 493px) {

  .tabs-navbar {
    margin-bottom: 10px;
  }

  .invite-btn {
    margin-bottom: 10px;
  }

}

.select-companies {
  @media all and (max-width: 1121px) {
    margin-bottom: 20px;
  }
}

.second-buttons {

  @media all and (max-width: 1121px) {
    display: none !important;
  }
}

.menu-resp {
  display: none;

  @media all and (max-width: 1121px) {
    display: block;
  }

  @media all and (max-width: 942px) {
    margin-bottom: 10px;
  }
}

.long-menu {
  .MuiPaper-elevation8 {
    box-shadow: 0 1px 1px -1px rgb(0, 0, 0 / 20%),
    0px 1px 0px 0px rgb(220,220,220), 0px 1px 2px 0px rgb(220,220,220);
  }
}
