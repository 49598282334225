@import "../style/variables";

.main-content {
  width: 100% !important;
  margin-top: 0!important;
  .appointments-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 26px;
    padding: 11px 20px 10px 20px;
    @media all and (max-width: 576px) {
      flex-direction: column;
    }
    .title-appointments {
      align-self: center;
      p {
        font: 400 22px/25px $main-font;
        color: $black;
      }
    }
    .top-bar-right {
      display: flex;
      justify-content: flex-end;
      width: 30%;
      @media all and (max-width: 1024px) {
        flex-direction: column;
      }
      @media all and (max-width: 576px) {
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}

.round-shape {
  border-radius: 50% !important;
}

.main-content.appointments,
.main-content.reminders {
  display: grid;
}

.MuiPaper-rounded {
    .MuiTableRow-root {
      .service-name {
        width: 45%;
        min-width: 85px;
      }
      .duration, .server-name {
        font: 400 14px/150% $main-font;
        color: $main-grey;
        margin-bottom: 0;
        margin-top: -6px;
      }
      .icon-edit {
        margin-right: 20px;
      }
      .status {
        max-width: 94px;
        p {
          font: 400 16px/25px $main-font;
          text-align: center;
          color: $white;
          padding: 1px 0;
          margin-bottom: 0;
        }
      }
      .appointment-status {
        text-align: center;
        padding: 4px 0;
        color: $white;
        border-radius: 3px;
        &.pending {
          background-color: $main-orange;
        }
        &.finished {
          background-color: $main-blue;
          cursor: default;
        }
        &.cancelled {
          background-color: $main-grey;
          cursor: default;
        }
        &.accepted {
          background-color: $main-green;
        }
      }
      .appointment-duration,
      .staff-name
      {
        font-size: 14px;
        color: $main-grey;
      }
    }
  }

.appointment-status {
  text-align: center;
  padding: 4px 0;
  color: $white;
  border-radius: 3px;
  height: 30px;

  .MuiOutlinedInput-inputMarginDense {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .MuiMenuItem-root {
    color: unset;
  }

  .MuiListItem-button:hover {
    background-color: transparent;
  }

  &.pending {
    background-color: $main-orange;
  }
  &.finished {
    background-color: $main-blue;
  }
  &.cancelled {
    background-color: $main-grey;
  }
  &.accepted {
    background-color: $main-green !important;
  }
}

.appointment-date,
.appointment-company-name {
  font-weight: 600 !important;
  font-size: 16px;
}

.filter-menu, .filter-staff-menu {
  margin-top: 19px;
  ul {
    padding: 25px;
    max-width: 280px;
    h5 {
      font-size: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 600;
    }
    li {
      font: 500 14px/150% $main-font;
      color: $main-grey;
      padding: 0 !important;
      display: flex;
      justify-content: space-between;

      .filter-label {
        align-self: center;
      }

      .MuiFormControl-marginNormal {
        width: 150px !important;
        border: 1px solid $main-grey;
        border-radius: 4px;
        padding-left: 6px;
        margin-top: 8px;
      }
    }
    .MuiInput-underline {
      &:after {
        border-bottom: none !important;
      }
      &:before {
        border-bottom: none !important;
      }
    }
    .date-picker {
      height: 44px !important;
      &:hover {
        background-color: transparent !important;
      }
      .MuiIconButton-root {
        padding: 4px;
      }
    }
  }
}

.filter-staff-menu {
  .MuiMenu-paper {
    top: 100px !important;
  }
  ul {
    top: 0;
    padding: 15px;
    max-width: 300px;
    h5 {
      font-size: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 500;
    }
  }
}

.date-to {
  margin-left: 5px;
}

.date-from, .date-to {
  .MuiInputBase-input {
    padding: 4px 0 7px!important;
  }
  p {
    font: 500 14px/150% $main-font;
    color: $main-grey;
    margin-top: 16px;
    margin-bottom: 0!important;
  }
}

.main-content.appointments .box-general {
  margin-top: 50px;
}

.appointments-page {
  height: calc(100vh - 129px);
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 550px) {
    img {
      max-width: 320px;
      width: 100%;
    }
  }
}

.customers-page,
.industry-page,
.company-page,
.service-page,
.staff-page {
  height: calc(100vh - 169px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;
  z-index: -1;

  @media all and (max-width: 768px) {
    position: unset;
  }

  @media all and (max-width: 550px) {
    img {
      max-width: 320px;
      width: 100%;
    }
  }
}

.not-work-day {
  height: calc(100vh - 128px - 164px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointment-title {
  border-right: 2px solid $main-grey;
  padding-right: 24px;
  margin-right: 24px;
}

.selectSize.several-company {
  margin-left: 10px;
}

.calendar-none {
  .fc-unthemed th {
    border: none;
  }
  .fc-body {
    display: none;
  }
}

.MuiMenuItem-root.menu-item-status {
  width: 146px;
  height: 30px;
  display: flex;
  justify-content: center;
  padding: 4px 0;
  color: $white;
  border-radius: 3px;
}

.default-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  border-radius: 50px;
  background: #F2F9FD;
  border: 0.5px solid #A2D1F2;
  color: #A2D1F2;
}

.avatar-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  border-radius: 50px;
}

.statusItem {
  background-color: unset !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
}