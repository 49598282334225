@import "../../../../../style/_variables";

#customers {
  .page-top-bar {
    .filter-select {
      width: 150px;
      margin: 0 20px;

      .css-yk16xz-control {
        &:active {
          height: 40px;
        }
      }
    }
  }

  .customers-line {
    .customer-item {
      .actions-dropdown {
        position: absolute;
        top: 5px;
        right: 15px;

        .dropdown-toggle {
          flex-direction: row;
          align-items: center;

          &:before, i {
            margin-right: 2px;
          }
        }
      }

      .image-content {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        img {
          min-width: 100%;
          width: 100%;
          height: 100%;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .customer-item-box {
    margin-top: 50px;

  }
}
.add-staff-btn {
  margin-top: 8px !important;
  max-height: 55px;
}

.country-flag {
  margin-right: 5px;
  vertical-align: middle;
}

.appointments-delete-context {
  font: normal normal normal 16px/21px $main-font;
  letter-spacing: 0.15px;
  color: #000305;
}

.appointments-delete-context {

  p:not(:first-child) {
    line-height: 5px;
  }
}

.appointments-delete-context {
  p:nth-child(1) {
    padding-bottom: 20px;
  }
}

.appointments-delete-context-text {
  color: $main-blue;
}

.justify-content-right .save-btn {
  border-radius: 5px !important;
  color: $white !important;
  text-transform: none !important;
  &:hover {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
    text-shadow: 0 0 6px white;
    transition: all 0.4s ease 0s;
  }
}

.dialog-delete-modal {
  .save-btn {
    border-radius: 5px !important;
    color: $white !important;
    text-transform: none !important;
  }
}

.tab-customers .box-general {
  box-shadow: none;
  .tab-content-customers {
  }

  .no-customer {
    margin: auto;
    font-size: 2em;
    color: #8c949d;
  }
}

.customer-company-name {
  display: flex;
}

.customers-customers-line {
  .col-auto.customer-item {
    .actions-dropdown-customer {
      padding: 0 !important;
      position: relative;

      .image-content {
        margin-top: 26.79px;
        text-align: center;
        img {
          width: 47%;
        }
      }
      .customer-email,
      .customer-company-name,
      .company-title {
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 10px;
        margin: 0;
        white-space: nowrap;
        text-align: center;
        font-size: 11px;
        line-height: 10px;
      }
      .customer-company-name {
        padding: 0 1px;
      }
      .company-title {
        padding-left: 6px;
        font-size: 12px;
      }
    }
  }
}

.second-modal {
  table {
    margin-bottom: 0 !important;
  }
  .alert-title {
    font-weight: normal;
  }
  .table-title {
    font-size: 18px;
  }
  .close-btn {
    top: -15px;
    padding: 0;
  }
  .permission-name {
    font-size: 22px;
  }
}

.row {
  cursor: pointer;

  .default-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    border-radius: 50px;
    //background: #F2F9FD;
    //border: 0.5px solid #A2D1F2;
    //color: #A2D1F2;
  }

  .avatar-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    border-radius: 50px;
  }

  .row-item {
    font-size: 14px;
    color: $gray;
    font-family: $backup-font;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;

    &.action {
      padding: 6px;
    }

    &.email {
      color: $main-blue;
    }

    .action-button {
      padding: 10px;
    }

    &.selectCheckbox {
      padding: 0 16px 0 16px;
    }

  }
}

.data-table {
  min-width: 750px;

  .MuiCheckbox-root {
    color: #E3E5E6;

    &:hover {
      background-color: unset !important;
      color: $main-blue,
    }
  }

  .checkbox {
    padding: 0 16px 0 16px;
  }
}

.menu-list {
  filter: drop-shadow(0px 32px 64px rgba(146, 148, 165, 0.16));

  .item {
    padding-bottom: 10px;
    padding-top: 10px;

    .icon {
      color: $gray;
      margin-right: 15px;

      &.delete-icon {
        color: #F12B2C;
      }
    }

    p {
      color: #393939;

      &.delete {
        color: #F12B2C;
      }
    }
  }
}

.pagination {

  .MuiTypography-root {
    color: #9E9E9E;
  }
}

.delete-modal {

  .MuiDialog-paper {
    width: 360px;
    min-width: 360px;

    .modal-content {
      padding-top: 0;

      .close {
        text-align: end;
        margin-top: 20px;

        button {
          padding: 0;
        }
      }

      .warning-icon {
        width: 50px;
        height: 50px;
        background-color: #FFF3F3;
        border-radius: 50px;
        text-align: center;
        line-height: 50px;
        margin: 0 auto 20px;
      }

      .title {
        text-align: center;
        font-size: 16px;
        color: #393939;
      }
    }

    .buttons {
      justify-content: center;
      padding: 20px 24px;
    }
  }
}

.button-box {
  display: inline-table;
  height: 39px;

  button {
    height: 100%;
    padding: 4px 16px;
    font: normal 500 14px/19px Nunito Sans, sans-serif;
  }
}